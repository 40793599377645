const commonConfiguration: ICommonAppConfiguration = {
    recaptcha: {
        key: '6LdOUv4ZAAAAAPjFd6hqqEc_SY9ldOL0ZrlG4-O9',
    },
};

const config: IPerEnvironmentAppConfiguration = {
    local: {
        ...commonConfiguration,
        api: {
            endpoint: 'https://localhost:5001/api',
        },
    },
    development: {
        ...commonConfiguration,
        api: {
            endpoint: 'https://development.api.omni.oppy.ai/api',
        },
    },
    staging: {
        ...commonConfiguration,
        api: {
            endpoint: 'https://staging.api.omni.oppy.ai/api',
        },
    },
    production: {
        ...commonConfiguration,
        api: {
            endpoint: 'https://api.omni.oppy.ai/api',
        },
    },
};

export interface IPerEnvironmentAppConfiguration {
    local: IAppConfiguration,
    development: IAppConfiguration,
    staging: IAppConfiguration,
    production: IAppConfiguration,
}

export interface ICommonAppConfiguration {
    recaptcha: {
        key: string,
    },
}

export interface IAppConfiguration extends ICommonAppConfiguration {
    api: {
        endpoint: string,
    },
}

// eslint-disable-next-line no-process-env
export const appConfiguration: IAppConfiguration = config[process.env.REACT_APP_ENV ?? 'local'];
