import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '../../services/contexts/auth/useAuthContext';

export const useLogout = () => {
    const { setAuthContext } = useAuthContext();
    const navigateTo = useNavigate();

    const handleLogout = useCallback(() => {
        setAuthContext({
            isAuthenticated: false,
        });
        navigateTo('/auth/disconnected');
    }, [navigateTo, setAuthContext]);

    return {
        handleLogout,
    };
};
