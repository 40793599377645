import { useState, useMemo, useCallback } from 'react';

import { LocalStorageUtility } from '../../storage/LocalStorageUtility';
import { AuthContext, IAuthContext } from './AuthContext';

export const localStorageEntityKey = 'omni_meeting_auth';

interface IEntityProviderProps {
    children: React.ReactNode,
}

export const AuthProvider = ({ children }: IEntityProviderProps) => {
    const loadAuthContext = useCallback((): IAuthContext => {
        const authContext = LocalStorageUtility.getValue(localStorageEntityKey);

        if (authContext) {
            return authContext;
        }

        const defaultAuthContext: IAuthContext = {
            isAuthenticated: false,
        };

        LocalStorageUtility.setValue(authContext, defaultAuthContext);

        return defaultAuthContext;
    }, []);

    const [authContextState, setAuthContextState] = useState<IAuthContext>(loadAuthContext());

    const setAuthContext = useCallback((authContext: IAuthContext) => {
        LocalStorageUtility.setValue(localStorageEntityKey, authContext);
        setAuthContextState(authContext);
    }, []);

    const authContextProviderValue = useMemo(() => ({
        authContext: authContextState,
        setAuthContext,
    }), [authContextState, setAuthContext]);

    return (
        <AuthContext.Provider value={authContextProviderValue}>
            {children}
        </AuthContext.Provider>
    );
};
