import { useCallback } from 'react';

import { useAuthenticatedApi } from '../useApi';

interface IAuthenticateUserParameter {
    login: string,
    password: string,
    mfaCode: string,
    recaptchaToken: string,
}

interface IAuthenticateUserDataContract {
    accessToken: string,
}

export const useAuthenticateUser = () => {
    const authenticatedApi = useAuthenticatedApi();

    const authenticateUser = useCallback((
        authenticateUserParameter: IAuthenticateUserParameter,
    ): Promise<IAuthenticateUserDataContract> => (
        authenticatedApi
            .post<IAuthenticateUserDataContract>('/auth/login/user', authenticateUserParameter)
            .then((response) => response.data)
    ), [authenticatedApi]);

    return authenticateUser;
};
