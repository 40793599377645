import { Navigate } from 'react-router-dom';

import { useAuthContext } from '../../services/contexts/auth/useAuthContext';

interface INeedsAuthProps {
    children: React.ReactNode,
    fallbackPathOverride?: string,
}

export const NeedsAuth = ({
    children,
    fallbackPathOverride,
}: INeedsAuthProps) => {
    const { authContext: { isAuthenticated } } = useAuthContext();

    return (
        isAuthenticated
            ? <>{children}</>
            : <Navigate replace to={fallbackPathOverride ?? '/'} />
    );
};
