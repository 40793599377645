import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

import { FlexBox } from '../FlexBox';
import { OmniButton } from './OmniButton';

const Link = styled(RouterLink)`
    text-decoration: none;
`;

interface ILinkButtonProps {
    to: string,
    children: React.ReactNode,
}

export const LinkButton = ({
    to,
    children,
}: ILinkButtonProps) => (
    <Link to={to}>
        <FlexBox>
            <OmniButton>{children}</OmniButton>
        </FlexBox>
    </Link>
);
