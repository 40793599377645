import Typography from '@mui/material/Typography';

import { FlexBox } from '../FlexBox';

interface ITitleProps {
    children: string,
}

export const Title = ({ children }: ITitleProps) => (
    <FlexBox>
        <Typography variant="h6" fontWeight="bold">
            {children}
        </Typography>
    </FlexBox>
);
