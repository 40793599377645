import { useCallback } from 'react';

import { IMeetingConfiguration } from '../../../types/IMeetingConfiguration';
import { useAuthenticatedApi } from '../useApi';

export const useLoadMeetingAgentConfiguration = () => {
    const authenticatedApi = useAuthenticatedApi();

    const loadMeetingAgentConfiguration = useCallback((): Promise<IMeetingConfiguration> => (
        authenticatedApi
            .get<IMeetingConfiguration>('/meeting/visio/agent')
            .then((response) => response.data)
    ), [authenticatedApi]);

    return loadMeetingAgentConfiguration;
};
