import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { FlexBox } from '../FlexBox';
import { CircularLoader } from './CircularLoader';

export const LoaderDialog = () => (
    <Dialog
        open={true}
        maxWidth="sm"
        fullWidth={true}
    >
        <DialogContent>
            <FlexBox>
                <CircularLoader />
            </FlexBox>
        </DialogContent>
    </Dialog>
);
