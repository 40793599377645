import { useCallback, useEffect, useState } from 'react';

import { VonageMeetingRoom } from '@oppy/meetingcore';

import { useLogout } from '../../../components/auth/useLogout';
import { AlertDialog } from '../../../components/ui/alert/AlertDialog';
import { LoaderDialog } from '../../../components/ui/loader/LoaderDialog';
import { PageContainer } from '../../../components/ui/PageContainer';
import { useEndMeeting } from '../../../services/api/meeting/useEndMeeting';
import { useLoadMeetingAgentConfiguration } from '../../../services/api/meeting/useLoadMeetingAgentConfiguration';
import { useTranslation } from '../../../services/i18n/useTranslation';
import { IMeetingConfiguration } from '../../../types/IMeetingConfiguration';
import { AgentMeetingToolbar } from './AgentMeetingToolbar';

export const AgentMeetingPage = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isInError, setIsInError] = useState(false);
    const { handleLogout } = useLogout();
    const endMeeting = useEndMeeting();
    const loadMeetingAgentConfiguration = useLoadMeetingAgentConfiguration();
    const [meetingConfiguration, setMeetingConfiguration] = useState<IMeetingConfiguration>();
    const [lastReload, setLastReload] = useState(Date.now);

    useEffect(() => {
        setIsLoading(true);
        setIsInError(false);
        loadMeetingAgentConfiguration()
            .then((meetingConfigurationResult) => {
                setMeetingConfiguration(meetingConfigurationResult);
            })
            .catch(() => {
                setIsInError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    // (Tanguy S) 14/03/2022
    // Configuration is queried at page init.
    // It can also be force reloaded by clicking the reload button or
    // when a meeting end.
    }, [loadMeetingAgentConfiguration, lastReload]);

    const handleEndMeeting = useCallback(() => {
        endMeeting()
            .catch();
    }, [endMeeting]);

    const handleReload = useCallback(() => {
        setLastReload(Date.now);
    }, []);

    return (
        <PageContainer>
            {
                meetingConfiguration && (
                    <>
                        <VonageMeetingRoom
                            meetingConfiguration={meetingConfiguration}
                            translations={
                                {
                                    error: {
                                        title: t('meeting.common.error.title'),
                                        code: (openTokErrorCode) => t('meeting.common.error.code', {
                                            code: openTokErrorCode,
                                        }),
                                        content: {
                                            accessCameraAndMicrophone:
                                                t('meeting.common.error.content.accessCameraAndMicrophone'),
                                        },
                                    },
                                    loading: {
                                        title: t('meeting.common.loading.title'),
                                    },
                                }
                            }
                        />
                        <AgentMeetingToolbar
                            handleLogout={handleLogout}
                            handleEndMeeting={handleEndMeeting}
                            handleReload={handleReload}
                        />
                    </>
                )
            }
            {
                isLoading && (
                    <LoaderDialog />
                )
            }
            {
                isInError && (
                    <AlertDialog
                        title={t('meeting.agent.error.title')}
                        content={t('meeting.agent.error.content')}
                        actions={
                            [
                                {
                                    label: 'Retry',
                                    handleClick: handleReload,
                                },
                                {
                                    label: 'Logout',
                                    handleClick: handleLogout,
                                },
                            ]
                        }
                    />
                )
            }
        </PageContainer>
    );
};
