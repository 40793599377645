import { default as i18n } from 'i18next';
import { default as LanguageDetector } from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import resources from './locales';

export const initI18n = () => {
    i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            fallbackLng: 'en',
            debug: true,
            resources,
            react: {
                // (Tanguy S) 15/03/2022
                // We don't need to use suspense here because our locales
                // are bundled with the rest of the app.
                useSuspense: false,
            },
            interpolation: {
                // Not needed for react as it escapes by default
                escapeValue: false,
            },
        });
};
