import React from 'react';

import TextField from '@mui/material/TextField';

interface ITextFormProps {
    label: string,
    name: string,
    value: string | undefined,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    error?: string,
    type?: React.InputHTMLAttributes<unknown>['type'],
}

export const TextForm = React.memo(({
    label,
    name,
    value,
    handleChange,
    error,
    type,
}: ITextFormProps) => (
    <TextField
        type={type}
        label={label}
        name={name}
        value={value}
        onChange={handleChange}
        error={error !== undefined}
        helperText={error}
    />
));
