import Box from '@mui/material/Box';

interface IFlexBoxProps {
    children: React.ReactNode,
    align?: 'center' | 'left',
    isColumn?: boolean,
    gap?: string,
}

export const FlexBox = ({ children, align, isColumn, gap }: IFlexBoxProps) => (
    <Box
        display="flex"
        justifyContent={align ?? 'center'}
        flexDirection={isColumn ? 'column' : 'row'}
        alignItems="center"
        gap={gap}
    >
        {children}
    </Box>
);
