import { Fragment } from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

interface IAlertDialogActions {
    label: string,
    handleClick: () => void,
}

interface IAlertDialogProps {
    title: string,
    content: string,
    actions?: IAlertDialogActions[],
}

export const AlertDialog = ({
    title,
    content,
    actions,
}: IAlertDialogProps) => (
    <Dialog
        open={true}
        maxWidth="sm"
        fullWidth={true}
    >
        <Alert severity="error">
            <AlertTitle>
                <Typography noWrap fontWeight="bold">
                    {title}
                </Typography>
            </AlertTitle>
            {content}
        </Alert>
        {
            actions && (
                <DialogActions>
                    {
                        actions?.map((action) => (
                            <Fragment key={action.label}>
                                <Button onClick={action.handleClick}>
                                    {action.label}
                                </Button>
                            </Fragment>
                        ))
                    }
                </DialogActions>
            )
        }
    </Dialog>
);
