import { useCallback } from 'react';

import { useAuthenticatedApi } from '../useApi';

export const useEndMeeting = () => {
    const authenticatedApi = useAuthenticatedApi();

    const endMeeting = useCallback((): Promise<void> => (
        authenticatedApi
            .get<void>('meeting/visio/end')
            .then((response) => response.data)
    ), [authenticatedApi]);

    return endMeeting;
};
