import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { NeedsAuth } from '../components/router/NeedsAuth';
import { RedirectIfAuthenticated } from '../components/router/RedirectIfAuthenticated';
import { AuthProvider } from '../services/contexts/auth/AuthProvider';
import { AuthPage } from './auth/AuthPage';
import { AgentMeetingPage } from './meeting/agent/AgentMeetingPage';
import { MeetingPage } from './meeting/MeetingPage';
import { RootPage } from './root/RootPage';

export const App = () => (
    <AuthProvider>
        <BrowserRouter>
            <Routes>
                <Route
                    path="/meeting/agent"
                    element={
                        <NeedsAuth>
                            <AgentMeetingPage />
                        </NeedsAuth>
                    }
                />
                <Route
                    path="/meeting/:broadcastToken"
                    element={<MeetingPage />}
                />
                <Route
                    path="/auth/disconnected"
                    element={
                        <RedirectIfAuthenticated authenticatedFallbackPath="/meeting/agent">
                            <RootPage isLogout />
                        </RedirectIfAuthenticated>
                    }
                />
                <Route
                    path="/auth"
                    element={
                        <RedirectIfAuthenticated authenticatedFallbackPath="/meeting/agent">
                            <AuthPage />
                        </RedirectIfAuthenticated>
                    }
                />
                <Route
                    path="/"
                    element={
                        <RedirectIfAuthenticated authenticatedFallbackPath="/meeting/agent">
                            <RootPage />
                        </RedirectIfAuthenticated>
                    }
                />
                <Route
                    path="*"
                    element={<Navigate replace to="/" />}
                />
            </Routes>
        </BrowserRouter>
    </AuthProvider>
);
