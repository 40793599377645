import { Navigate } from 'react-router-dom';

import { useAuthContext } from '../../services/contexts/auth/useAuthContext';

interface IRedirectIfAuthenticatedProps {
    children: React.ReactNode,
    authenticatedFallbackPath: string,
}

export const RedirectIfAuthenticated = ({
    children,
    authenticatedFallbackPath,
}: IRedirectIfAuthenticatedProps) => {
    const { authContext: { isAuthenticated } } = useAuthContext();

    return (
        isAuthenticated
            ? <Navigate replace to={authenticatedFallbackPath} />
            : <>{children}</>
    );
};
