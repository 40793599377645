import { default as en } from './en/index.json';
import { default as fr } from './fr/index.json';

export default {
    fr: {
        translation: fr,
    },
    en: {
        translation: en,
    },
};
