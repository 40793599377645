import styled from '@emotion/styled';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ReplayIcon from '@mui/icons-material/Replay';

import { FloatingActionButton } from '../../../components/ui/buttons/FloatingActionButton';
import { FlexBox } from '../../../components/ui/FlexBox';

const ToolbarBase = styled.div`
    position: absolute;
    bottom: 1em;
    left: 0;
    width: 100%;
    z-index: 11;
`;

interface IAgentMeetingToolbarProps {
    handleEndMeeting: () => void,
    handleLogout: () => void,
    handleReload: () => void,
}

export const AgentMeetingToolbar = ({
    handleEndMeeting,
    handleLogout,
    handleReload,
}: IAgentMeetingToolbarProps) => (
    <ToolbarBase>
        <FlexBox gap="1em">
            <FloatingActionButton color="error" handleClick={handleEndMeeting}>
                <CallEndIcon />
            </FloatingActionButton>
            <FloatingActionButton color="info" handleClick={handleLogout}>
                <ExitToAppIcon />
            </FloatingActionButton>
            <FloatingActionButton color="info" handleClick={handleReload}>
                <ReplayIcon />
            </FloatingActionButton>
        </FlexBox>
    </ToolbarBase>
);
