import axios, { AxiosRequestConfig } from 'axios';

import { appConfiguration } from '../config/config';

export const axiosOptions: AxiosRequestConfig = {
    baseURL: appConfiguration.api.endpoint,
};

export const anonymousAxiosService = axios.create(axiosOptions);

export const authenticatedAxiosService = axios.create(axiosOptions);
