import { useCallback, useState } from 'react';

import { load as recaptchaLoad } from 'recaptcha-v3';

import { FormController } from '../../components/form/FormController';
import { OmniButton } from '../../components/ui/buttons/OmniButton';
import { Divider } from '../../components/ui/forms/Divider';
import { TextForm } from '../../components/ui/forms/TextForm';
import { PageContainer } from '../../components/ui/PageContainer';
import { Alert } from '../../components/ui/texts/Alert';
import { Paragraph } from '../../components/ui/texts/Paragraph';
import { Title } from '../../components/ui/texts/Title';
import { useAuthenticateUser } from '../../services/api/auth/useAuthenticateUser';
import { appConfiguration } from '../../services/config/config';
import { useAuthContext } from '../../services/contexts/auth/useAuthContext';
import { useTranslation } from '../../services/i18n/useTranslation';

interface IAuthFormModel {
    login: string,
    password: string,
    mfaCode: string,
}

export const AuthPage = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isInError, setIsInError] = useState(false);
    const { setAuthContext } = useAuthContext();
    const authenticateUser = useAuthenticateUser();

    const submitForm = useCallback((formData: Partial<IAuthFormModel>) => {
        setIsLoading(true);
        setIsInError(false);

        recaptchaLoad(appConfiguration.recaptcha.key,
            {
                useRecaptchaNet: true,
                autoHideBadge: true,
            })
            .then((captcha) => captcha.execute('submit'))
            .then((recaptchaToken) => (
                authenticateUser({
                    login: formData.login ?? '',
                    password: formData.password ?? '',
                    mfaCode: formData.mfaCode ?? '',
                    recaptchaToken,
                })
            ))
            .then((result) => {
                // Save the access token somewhere
                setAuthContext({
                    isAuthenticated: true,
                    accessToken: result.accessToken,
                });
            })
            .catch(() => {
                setIsInError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [authenticateUser, setAuthContext]);

    return (
        <PageContainer>
            <Title>{t('auth.title')}</Title>
            <Paragraph>{t('auth.description')}</Paragraph>
            <FormController
                initialValues={
                    {
                        login: '',
                        password: '',
                        mfaCode: '',
                    }
                }
                onSubmit={submitForm}
            >
                {
                    ({ values, handleChange, errors }) => (
                        <>
                            <TextForm
                                label={t('auth.form.email.label')}
                                name="login"
                                value={values.login}
                                handleChange={handleChange}
                                error={errors.login}
                                type="email"
                            />
                            <TextForm
                                label={t('auth.form.password.label')}
                                name="password"
                                value={values.password}
                                handleChange={handleChange}
                                error={errors.password}
                                type="password"
                            />
                            <Divider />
                            <Paragraph>{t('auth.form.mfaCode.title')}</Paragraph>
                            <TextForm
                                label={t('auth.form.mfaCode.label')}
                                name="mfaCode"
                                value={values.mfaCode}
                                handleChange={handleChange}
                                error={errors.mfaCode}
                            />
                            {
                                isInError && (
                                    <Alert severity="error">
                                        {t('auth.form.error')}
                                    </Alert>
                                )
                            }
                            <OmniButton type="submit" disabled={isLoading}>{t('auth.form.submit.button')}</OmniButton>
                        </>
                    )
                }
            </FormController>
            {/* Padding du pauvre: */}
            <Paragraph>{''}</Paragraph>
        </PageContainer>
    );
};
