import MuiAlert from '@mui/material/Alert';

interface IAlertProps {
    children: React.ReactNode,
    severity: 'success' | 'info' | 'warning' | 'error',
}

export const Alert = ({
    children,
    severity,
}: IAlertProps) => (
    <MuiAlert severity={severity}>{children}</MuiAlert>
);
