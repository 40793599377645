import { useCallback, useEffect } from 'react';

import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '../contexts/auth/useAuthContext';
import { anonymousAxiosService, authenticatedAxiosService } from './axiosService';

export const useAuthenticatedApi = (): AxiosInstance => {
    const { authContext, setAuthContext } = useAuthContext();
    const navigateTo = useNavigate();

    const requestInterceptor = useCallback((config: AxiosRequestConfig): AxiosRequestConfig => {
        const token = authContext.accessToken;

        if (token !== undefined) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            };
        }

        return config;
    }, [authContext.accessToken]);

    const responseInterceptor = useCallback((response: AxiosResponse) => response, []);

    const requestError = useCallback((error: AxiosError) => error, []);

    const responseError = useCallback((error: AxiosError) => {
        const errorResponse = error.response;

        if (errorResponse && errorResponse.status === 401) {
            setAuthContext({
                isAuthenticated: false,
            });
            navigateTo('/auth/disconnected');
        }

        return Promise.reject(error);
    }, [navigateTo, setAuthContext]);

    useEffect(() => {
        const axiosRequestInterceptor = authenticatedAxiosService.interceptors.request.use(
            requestInterceptor,
            requestError,
        );
        const axiosResponseInterceptor = authenticatedAxiosService.interceptors.response.use(
            responseInterceptor,
            responseError,
        );

        return () => {
            authenticatedAxiosService.interceptors.request.eject(axiosRequestInterceptor);
            authenticatedAxiosService.interceptors.response.eject(axiosResponseInterceptor);
        };
    }, [requestError, requestInterceptor, responseError, responseInterceptor]);

    return authenticatedAxiosService;
};

export const useAnonymousApi = (): AxiosInstance => (
    anonymousAxiosService
);
