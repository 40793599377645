import { useCallback } from 'react';

import { IMeetingConfiguration } from '../../../types/IMeetingConfiguration';
import { useAnonymousApi } from '../useApi';

export const useLoadMeetingContactConfiguration = () => {
    const anonymousApi = useAnonymousApi();

    const loadMeetingContactConfiguration = useCallback((broadcastToken: string): Promise<IMeetingConfiguration> => (
        anonymousApi
            .get<IMeetingConfiguration>(`/meeting/visio/${broadcastToken}`)
            .then((response) => response.data)
    ), [anonymousApi]);

    return loadMeetingContactConfiguration;
};
