import { LinkButton } from '../../components/ui/buttons/LinkButton';
import { PageContainer } from '../../components/ui/PageContainer';
import { Alert } from '../../components/ui/texts/Alert';
import { Paragraph } from '../../components/ui/texts/Paragraph';
import { Title } from '../../components/ui/texts/Title';
import { useTranslation } from '../../services/i18n/useTranslation';

interface IRootPageProps {
    isLogout?: boolean,
}

export const RootPage = ({ isLogout }: IRootPageProps) => {
    const { t } = useTranslation();

    return (
        <PageContainer>
            {
                isLogout && (
                    <Alert severity="warning">{t('root.disconnected')}</Alert>
                )
            }
            <Title>{t('common.app.title')}</Title>
            <Paragraph>{t('root.description')}</Paragraph>
            <LinkButton to="/auth">{t('root.login.button')}</LinkButton>
        </PageContainer>
    );
};
