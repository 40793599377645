import { createContext } from 'react';

export interface IAuthContext {
    accessToken?: string,
    isAuthenticated: boolean,
}

interface IAuthContextState {
    authContext: IAuthContext,
    setAuthContext: (authContext: IAuthContext) => void,
}

export const AuthContext = createContext<IAuthContextState>({
    authContext: {
        isAuthenticated: false,
    },
    setAuthContext: () => {
        // Nothing here.
    },
});
