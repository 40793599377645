import { useEffect, useState } from 'react';

import { VonageMeetingRoom } from '@oppy/meetingcore';
import { useParams } from 'react-router-dom';

import { AlertDialog } from '../../components/ui/alert/AlertDialog';
import { LoaderDialog } from '../../components/ui/loader/LoaderDialog';
import { useLoadMeetingContactConfiguration } from '../../services/api/meeting/useLoadMeetingContactConfiguration';
import { useTranslation } from '../../services/i18n/useTranslation';
import { IMeetingConfiguration } from '../../types/IMeetingConfiguration';

export const MeetingPage = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isInError, setIsInError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [meetingConfiguration, setMeetingConfiguration] = useState<IMeetingConfiguration>();
    const { broadcastToken } = useParams<{ broadcastToken: string }>();
    const loadMeetingContactConfiguration = useLoadMeetingContactConfiguration();

    useEffect(() => {
        setIsLoading(true);
        setIsLoaded(false);
        setIsInError(false);

        loadMeetingContactConfiguration(broadcastToken ?? '')
            .then((loadMeetingConfigurationResult) => {
                setMeetingConfiguration(loadMeetingConfigurationResult);
                setIsLoaded(true);
            })
            .catch(() => {
                setIsInError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [loadMeetingContactConfiguration, broadcastToken]);

    return (
        <>
            {
                isLoaded && meetingConfiguration && (
                    <VonageMeetingRoom
                        meetingConfiguration={meetingConfiguration}
                        translations={
                            {
                                error: {
                                    title: t('meeting.common.error.title'),
                                    code: (openTokErrorCode) => t('meeting.common.error.code', {
                                        code: openTokErrorCode,
                                    }),
                                    content: {
                                        accessCameraAndMicrophone:
                                            t('meeting.common.error.content.accessCameraAndMicrophone'),
                                    },
                                },
                                loading: {
                                    title: t('meeting.common.loading.title'),
                                },
                            }
                        }
                    />
                )
            }
            {
                isLoading && (
                    <LoaderDialog />
                )
            }
            {
                isInError && (
                    <AlertDialog
                        title={t('meeting.customer.error.title')}
                        content={t('meeting.customer.error.content')}
                    />
                )
            }
        </>
    );
};
