import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';

interface IFloatingActionButtonProps {
    handleClick: () => void,
    children: React.ReactNode,
    title?: string,
    color?: 'default' | 'info' | 'error',
}

export const FloatingActionButton = ({
    handleClick,
    children,
    title,
    color,
}: IFloatingActionButtonProps) => (
    <Tooltip title={title ?? ''}>
        <Fab color={color ?? 'default'} onClick={handleClick}>
            {children}
        </Fab>
    </Tooltip>
);
