import Typography from '@mui/material/Typography';

interface IParagraphProps {
    children: string,
}

export const Paragraph = ({ children }: IParagraphProps) => (
    <Typography paragraph align="justify">
        {children}
    </Typography>
);
